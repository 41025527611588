.header {
  display: flex;
  justify-content: space-between;
}

.logo {
  width: 10rem;
  height: 3rem;
}

.header-menu {
  list-style: none;
  display: flex;
  gap: 2rem;
  color: white;
}

.header-menu > li {
  cursor: pointer;
}

.header-menu > li:hover {
  color: var(--orange);
  cursor: pointer;
  transition: ease-in-out 0.3s;
}

.close-btn {
  display: none;
}

@media screen and (max-width: 768px) {
  .header:nth-child(2) {
    position: fixed;
    right: 2rem;
    z-index: 999;
  }

  .header-menu {
    flex-direction: column;
    background-color: var(--appColor);
    padding: 2rem;
    z-index: 999;
    position: absolute;
    right: 2rem;
  }

  .bars {
    width: 2rem;
    height: 2rem;
    background-color: var(--appColor);
    padding: 0.5rem;
    border-radius: 5px;
    transition: ease-in-out 3ms;
  }
}
