.join {
  display: flex;
  padding: 0 2rem;
  gap: 10rem;
}

.left-j {
  color: white;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
}

.left-j > hr {
  position: absolute;
  width: 10.5rem;
  border: 2px solid var(--orange);
  margin: -2rem 0;
  border-radius: 20%;
}

.right-j {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.email-container {
  display: flex;
  gap: 3rem;
  background: gray;
  padding: 1rem 2rem;
}

.email-container > input {
  background: transparent;
  border: none;
  outline: none;
  color: var(--lightgray);
  font-size: 1rem;
}

::placeholder {
  color: var(--lightgray);
}

.btn-join {
  background-color: var(--orange);
  color: white;
}

@media screen and (max-width: 768px) {
  .join {
    flex-direction: column;
    gap: 1rem;
  }
}
